import Head from 'next/head';
import { useRouter } from 'next/router';

import { getJobsPublicURL } from '~/config';

interface AlternateLinksProps {
  paths?: Record<string, string>;
}

export const AlternateLinks = ({ paths }: AlternateLinksProps) => {
  const { locales, defaultLocale } = useRouter();

  const getPath = (locale: string) => (paths?.[locale] ? `/${paths?.[locale]}` : '');

  return (
    <Head>
      <link rel="alternate" href={`${getJobsPublicURL()}${getPath(defaultLocale)}`} hrefLang={defaultLocale} />
      {locales.map(locale => (
        <link
          key={locale}
          rel="alternate"
          hrefLang={locale}
          href={`${getJobsPublicURL()}/${locale}${getPath(locale)}`}
        />
      ))}
    </Head>
  );
};
