import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';

import { Select } from '~/components/Select';
import { formatLabel } from '~/components/Select/formatLabel';
import { useSearchFormContext } from '~/components/Welcome/useSearchFormContext';
import { formOptions } from '~/constants/formOptions';

export interface FiltersPanelSelect {
  hasDefaultOptionAll?: boolean;
  type: 'sort' | 'type' | 'experience';
}

export const FiltersPanelSelect = ({ type, hasDefaultOptionAll }: FiltersPanelSelect) => {
  const { t } = useTranslation('all');

  const options = useMemo(
    () =>
      (hasDefaultOptionAll ? ['all', ...formOptions[type]] : formOptions[type]).map((value: string) => ({
        value,
        label: t(`form_option_labels.${type}.${value.toLowerCase()}`),
      })),
    [type, hasDefaultOptionAll],
  );

  const { form } = useSearchFormContext();

  const { value, onChange } = form?.[type] || {};

  return (
    <Select
      placeholder={t(`form_placeholders.${type}`)}
      options={options}
      onChange={onChange}
      value={value || (hasDefaultOptionAll && 'all') || ''}
      formatLabel={formatLabel(options)}
    />
  );
};
