import { GetServerSideProps } from 'next';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSessionStorage } from 'react-use';

import { MISSIONS_PER_PAGE, SearchJobOfferOutput, searchJobOffers } from '~/business/JobOffer/searchJobOffers';
import { Container, InnerContainer, IziBanner, Layout, Welcome } from '~/components';
import { AlternateLinks } from '~/components/AlternateLinks';
import { FiltersPanel } from '~/components/FiltersPanel';
import { Missions, MissionsCounter, MissionsPaginationBar, NoMissionsResult } from '~/components/MissionsList';
import { MissionsContainer, SearchContainer } from '~/components/Welcome/styles';
import { SearchFormType } from '~/components/Welcome/types';
import { getJobsPublicURL } from '~/config/jobs';
import { defaultParams } from '~/constants/jobOfferSearch';
import FormContextProvider from '~/contexts/FormContext/FormContext';
import { OnSubmit } from '~/contexts/FormContext/types';
import { computeJobFiltersTrackedValues, EVENTS } from '~/contexts/TrackingContext';
import { useTrackingContext } from '~/hooks/useTrackingContext';
import { extractLocaleData } from '~/locale';
import { stringifyAddress } from '~/utils/address';
import { getParamsFromQuery, Params } from '~/utils/search';

interface IndexPageProps {
  params?: Params;
  pageCount: number;
  totalJobOffers?: number;
  jobOffers?: SearchJobOfferOutput[];
}

const NoMissionModal = dynamic(() => import('~/components/NoMissionModal'));

const CACHE_MAX_AGE = 1800; // 30min

const IndexPage: FC<IndexPageProps> = ({ jobOffers, totalJobOffers, params, pageCount }) => {
  const { t } = useTranslation('all');
  const { locale, push } = useRouter();
  const { trackPage, trackActiveMissionPageChange, trackJobOfferClicked, trackSearchResultsDisplayed } =
    useTrackingContext();

  const isSearchApplied = useMemo(() => Boolean(jobOffers), [jobOffers]);

  const [showNoMissionModal, setShowNoMissionModal] = useState(false);
  const [showFiltersPanelForm, setShowFiltersPanelForm] = useState(false);
  const [noMissionModalAlreadyShown, setNoMissionModalAlreadyShown] = useSessionStorage(
    'no_mission_modal_already_shown',
    false,
  );

  useEffect(() => {
    if (!noMissionModalAlreadyShown && isSearchApplied && !totalJobOffers) {
      setShowNoMissionModal(true);
      setNoMissionModalAlreadyShown(true);
    }
  }, [isSearchApplied, totalJobOffers]);

  const scrollToMissionsList = () => {
    const element = document.getElementById('missions_count');
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useEffect(() => {
    if (isSearchApplied) return;
    trackPage(EVENTS.welcomePageLoaded);
  }, [isSearchApplied]);

  const generateUrl = (newParams: Params) => {
    const paths: string[] = [];
    const query: Record<string, string> = {};

    const addNewPath = (paramName: string, paramValue: any) => {
      paths.push(`${t(`search_params.${paramName}`)}/${paramValue}`);
    };

    for (const paramName of Object.keys(newParams).sort()) {
      const paramValue = newParams[paramName];
      if (!paramValue) {
        continue;
      }
      switch (paramName) {
        case 'job':
          addNewPath('job', encodeURIComponent(paramValue));
          break;
        case 'distance':
          addNewPath('distance', `${paramValue}km`);
          break;
        case 'address':
          addNewPath('address', stringifyAddress(paramValue));
          break;
        case 'type':
        case 'experience':
          if (paramValue !== 'all') {
            addNewPath(paramName, t(`form_option_values.${paramName}.${paramValue.toLowerCase()}`));
          }
          break;
        case 'page':
          query[t('search_params.page')] = (paramValue > 1 && String(paramValue)) || undefined;
          break;
        case 'sort':
          query[t('search_params.sort')] =
            paramValue !== defaultParams.sort ? t(`form_option_values.sort.${paramValue}`) : undefined;
          break;
      }
    }
    const path = `/${locale}/${paths.sort((a, b) => a[0].localeCompare(b[0])).join('/')}`;

    const parsedQuery = queryString.stringify(query);

    if (parsedQuery) {
      return `${path}?${parsedQuery}`;
    }
    return path;
  };

  const showMissionList = async ({ newParams, page }: { newParams?: SearchFormType; page?: number }) => {
    const url = generateUrl({ ...params, ...newParams, page });
    await push(url, null, { scroll: false });
    scrollToMissionsList();
  };

  const jobFiltersTrackedValues = useMemo(
    () => computeJobFiltersTrackedValues(params, totalJobOffers),
    [params, totalJobOffers],
  );

  useEffect(() => {
    if (isSearchApplied) {
      trackSearchResultsDisplayed(jobFiltersTrackedValues);
    }
  }, [isSearchApplied, jobFiltersTrackedValues]);

  const handleFormSubmit: OnSubmit<SearchFormType> = async newParams => {
    await showMissionList({ newParams });
    setShowFiltersPanelForm(false);
  };

  const handlePageChange = async (page: number) => {
    trackActiveMissionPageChange({
      ...jobFiltersTrackedValues,
      pageNumber: page,
    });
    await showMissionList({ page });
  };

  const handleMissionClick = useCallback(
    (jobOffer: SearchJobOfferOutput) => {
      trackJobOfferClicked({
        ...jobFiltersTrackedValues,
        refId: jobOffer.refId,
        jobTitle: jobOffer.title,
      });
    },
    [jobFiltersTrackedValues],
  );

  const handleNoMissionClose = async () => {
    setShowNoMissionModal(false);
  };

  const handleToggleFiltersPanelForm = () => {
    setShowFiltersPanelForm(expanded => !expanded);
  };

  const generateMeta = (metaType: string, city?: string, zipcode?: string) => {
    if (city) {
      if (zipcode) {
        return t(`${metaType}_city&zipcode`, { city, zipcode });
      }
      return t(`${metaType}_city`, { city });
    }
    return t(metaType);
  };

  const { title, description, socialTitle, socialDescription, socialImage } = useMemo(
    () => ({
      description: t('welcome_page.meta_description'),
      socialImage: `${getJobsPublicURL()}/meta-image-${locale}.jpeg`,
      title: generateMeta('welcome_page.meta_title', params?.address?.city, params?.address?.zipcode),
      socialDescription: generateMeta('welcome_page.meta_social_description', params?.address?.city, null),
      socialTitle: generateMeta('welcome_page.meta_social_title', params?.address?.city, params?.address?.zipcode),
    }),
    [params],
  );

  return (
    <Layout
      title={title}
      description={description}
      socialTitle={socialTitle}
      socialImage={socialImage}
      socialDescription={socialDescription}
    >
      <AlternateLinks />
      <Container shouldHaveABackgroundColor>
        <FormContextProvider<SearchFormType>
          defaultValues={{
            job: params.job,
            sort: params.sort,
            type: params.type,
            address: params.address,
            distance: params.distance,
            experience: params.experience,
          }}
          onSubmit={handleFormSubmit}
          defaultSubmitted={Boolean(params.address)}
        >
          <Welcome.Header isSearchApplied={isSearchApplied} />
          <InnerContainer>
            {isSearchApplied && (
              <SearchContainer>
                <FiltersPanel
                  showFiltersPanelForm={showFiltersPanelForm}
                  onToggleFiltersPanelForm={handleToggleFiltersPanelForm}
                />
                <MissionsContainer space="l" showFiltersPanelForm={showFiltersPanelForm}>
                  {!!totalJobOffers && <MissionsCounter count={totalJobOffers} />}
                  <Missions jobOffers={jobOffers} onMissionClick={handleMissionClick} />
                  {isSearchApplied && !jobOffers.length && <NoMissionsResult />}
                  {pageCount > 1 && (
                    <MissionsPaginationBar
                      pageCount={pageCount}
                      currentPage={params.page}
                      onChangePage={handlePageChange}
                    />
                  )}
                </MissionsContainer>
              </SearchContainer>
            )}
            <IziBanner />
          </InnerContainer>
        </FormContextProvider>
      </Container>
      {showNoMissionModal && <NoMissionModal onClose={handleNoMissionClose} />}
    </Layout>
  );
};

export default IndexPage;

export const getServerSideProps: GetServerSideProps = async ({ locale, query, res }) => {
  try {
    res.setHeader('Cache-Control', `public, max-age=${CACHE_MAX_AGE}`);

    const country = extractLocaleData(locale).country;
    const params = await getParamsFromQuery(query, locale);
    if (params.address) {
      const { jobOffers, totalJobOffers } = await searchJobOffers({
        country,
        job: params.job,
        type: params.type,
        sort: params.sort,
        page: params.page,
        distance: params.distance,
        experience: params.experience,
        latitude: params.address.location.latitude,
        longitude: params.address.location.longitude,
      });
      return {
        props: {
          params,
          jobOffers,
          totalJobOffers,
          pageCount: Math.ceil(totalJobOffers / MISSIONS_PER_PAGE),
        },
      };
    }
    return { props: { params } };
  } catch (error) {
    console.error(error?.data?.error || error.message);
    return { notFound: true };
  }
};
