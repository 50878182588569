import { TAddress } from '@iziwork/l10n';

export const countryCodeMapping = new Map();
countryCodeMapping.set('fr', { language: 'fr', filterCountriesIso3Codes: ['FRA'] });
countryCodeMapping.set('it', { language: 'it', filterCountriesIso3Codes: ['ITA'] });

export interface AddressLocation {
  latitude: number;
  longitude: number;
}

export interface AddressWithNameAndLocation extends TAddress {
  name: string;
  location: AddressLocation;
}

export interface Prediction {
  id: string;
  address: string;
}

export const stringifyAddress = ({ name, zipcode, location }: AddressWithNameAndLocation): string =>
  `${[name, zipcode].filter(Boolean).join('+')}@${location.latitude},${location.longitude}`;

export const parseAddress = (address: string): AddressWithNameAndLocation | undefined => {
  const [cityWithZipcode, location = ''] = address.split('@');
  const [name, zipcode = ''] = cityWithZipcode.split('+');
  const [latitude, longitude] = location.split(',');
  if (name && parseFloat(latitude) && parseFloat(longitude)) {
    return {
      name,
      zipcode,
      location: { latitude: parseFloat(latitude), longitude: parseFloat(longitude) },
    };
  }
  return undefined;
};
