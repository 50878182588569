import { Button, Flex, Icon } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, useState } from 'react';

import { AddressSelect } from '~/components/AddressSelect';
import { DistanceSlider } from '~/components/DistanceSlider';
import { FilterField } from '~/components/FiltersPanel/FilterField';
import { FiltersHeaderInfo } from '~/components/FiltersPanel/FiltersHeaderInfo';
import { FiltersPanelSelect } from '~/components/FiltersPanel/FiltersPanelSelect';
import { JobSelect } from '~/components/JobSelect';
import { TabletAndMobileOnly } from '~/components/Styled';
import { useSearchFormContext } from '~/components/Welcome/useSearchFormContext';
import { useEffectIfMounted } from '~/hooks';
import { useIsMobileOrTablet } from '~/hooks/useIsMobileOrTablet';

import {
  CustomSearchButton,
  FiltersAndSearchButtonContainer,
  FiltersContainer,
  FiltersHeaderContainer,
  FiltersPanelContainer,
  HeaderTitle,
} from './FiltersPanel.styles';

interface FiltersPanelProps {
  showFiltersPanelForm: boolean;
  onToggleFiltersPanelForm: () => void;
}

export const FiltersPanel: FC<FiltersPanelProps> = ({ showFiltersPanelForm, onToggleFiltersPanelForm }) => {
  const { t } = useTranslation('all');
  const isMobileOrTablet = useIsMobileOrTablet();

  const {
    form: {
      sort: { value: sort },
      type: { value: type },
      distance: { value: distance },
      job: { defaultValue: defaultJob },
      experience: { value: experience },
      address: { defaultValue: defaultAddress },
    },
    submit,
    resetFields,
  } = useSearchFormContext();
  const [currentDistance, setCurrentDistance] = useState(distance);

  useEffectIfMounted(async () => {
    if (!isMobileOrTablet) {
      await submit();
    }
  }, [sort, type, experience, distance]);

  const handleToggleFiltersPanelForm = () => {
    onToggleFiltersPanelForm();
    resetFields();
  };

  return (
    <FiltersPanelContainer space="m">
      <FiltersHeaderContainer space="xs">
        <Flex.Row space="xs" justifyContent="space-between" alignItems="flex-end">
          <HeaderTitle>{t('form_title')}</HeaderTitle>
          <Button variant="transparent" size="s" onClick={handleToggleFiltersPanelForm}>
            <Icon size="xl" name="preference-filled" />
          </Button>
        </Flex.Row>
        <Flex.Row space="l">
          <FiltersHeaderInfo icon="location-filled" text={defaultAddress?.name} />
          {defaultJob && <FiltersHeaderInfo icon="work-filled" text={defaultJob} />}
        </Flex.Row>
      </FiltersHeaderContainer>
      <FiltersAndSearchButtonContainer showFiltersPanelForm={showFiltersPanelForm}>
        <FiltersContainer>
          <TabletAndMobileOnly>
            <FilterField type="address">
              <AddressSelect />
            </FilterField>
            <FilterField type="job">
              <JobSelect />
            </FilterField>
          </TabletAndMobileOnly>
          <FilterField type="sort">
            <FiltersPanelSelect type="sort" />
          </FilterField>
          <FilterField type="type">
            <FiltersPanelSelect type="type" hasDefaultOptionAll />
          </FilterField>
          <FilterField type="distance" labelData={{ km: currentDistance }}>
            <DistanceSlider onCurrentDistanceChange={setCurrentDistance} />
          </FilterField>
          <FilterField type="experience">
            <FiltersPanelSelect type="experience" hasDefaultOptionAll />
          </FilterField>
        </FiltersContainer>
        <CustomSearchButton />
      </FiltersAndSearchButtonContainer>
    </FiltersPanelContainer>
  );
};
