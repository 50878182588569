import styled from 'styled-components';

import { theme } from '~/themes/default';

export const SliderContainer = styled.div`
  background-color: ${theme.palette.white};
  padding: ${theme.space.component.x.l};
  border-radius: ${theme.radiuses.xxl};
`;

export const sliderStyles = {
  track: {
    borderColor: theme.colors.primary.normal,
    backgroundColor: theme.colors.primary.normal,
    height: 3,
  },
  handle: {
    color: theme.palette.white,
    borderColor: theme.palette.white,
    boxShadow: theme.shadows.default,
    opacity: 1,
    height: '1.25rem',
    width: '1.25rem',
    marginTop: '-0.5rem',
  },
  rail: {
    height: 3,
    backgroundColor: theme.palette.grey300,
  },
};
