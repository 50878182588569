import { FormFeedback } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import React, { PropsWithChildren } from 'react';

import { FilterFieldContainer, FilterLabel } from '~/components/FiltersPanel/FiltersPanel.styles';
import { useSearchFormContext } from '~/components/Welcome/useSearchFormContext';

type FilterFieldType = 'job' | 'address' | 'type' | 'sort' | 'distance' | 'experience';

interface FilterFieldProps {
  type: FilterFieldType;
  labelData?: Record<string, any>;
}

export const FilterField = ({ type, children, labelData }: PropsWithChildren<FilterFieldProps>) => {
  const { t } = useTranslation('all');
  const { form, submitted } = useSearchFormContext();

  const error = form[type]?.error;

  return (
    <FilterFieldContainer>
      <FilterLabel>{t(`form_labels.${type}`, labelData)}</FilterLabel>
      {children}
      {submitted && error && <FormFeedback intent="danger">{t(`form_errors.${type}`)}</FormFeedback>}
    </FilterFieldContainer>
  );
};
