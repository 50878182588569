import { Flex, Icon } from '@iziwork/design-system';
import { IconMap } from '@iziwork/design-system/dist/components/Icon/iconsMap';
import React from 'react';

import { Text } from '~/components/FiltersPanel/FiltersPanel.styles';

type FiltersHeaderInfoProps = { text: string; icon: keyof IconMap };

export const FiltersHeaderInfo = ({ icon, text }: FiltersHeaderInfoProps) => (
  <Flex.Row space="xs" flexWrap="nowrap" alignItems="flex-end">
    <Icon size="l" name={icon} />
    <Text>{text}</Text>
  </Flex.Row>
);
