import { Flex } from '@iziwork/design-system';
import styled from 'styled-components';

import { theme } from '~/themes/default';

export const SearchContainer = styled.div`
  display: flex;
  && > * {
    margin-left: 1.5rem;
  }
  && > *:first-child {
    margin-left: 0;
  }
  @media (max-width: ${theme.devices.tablet.max}) {
    flex-direction: column;
    && > * {
      margin-left: 0;
    }
  }
`;

export const MissionsContainer = styled(Flex.Column)<{ showFiltersPanelForm: boolean }>`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: ${theme.radiuses.l};
  @media (max-width: ${theme.devices.tablet.max}) {
    display: ${props => (props.showFiltersPanelForm ? 'none' : 'block')};
    padding: 1.5rem 0 1rem;
  }
`;
