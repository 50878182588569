import { Flex } from '@iziwork/design-system';
import styled, { css } from 'styled-components';

import { SearchButton } from '~/components/SearchButton';
import { palette, theme } from '~/themes/default';
import { tabletAndMobileOnlyMixin } from '~/utils';

export const FiltersPanelContainer = styled(Flex.Column)`
  @media (max-width: ${theme.breakpoints.m}) {
    width: 100%;
  }
`;

const container = css`
  background-color: ${theme.palette.blue50};
  border-radius: ${theme.radiuses.xl};
  color: ${palette.darkBlue};
  min-width: 100%;
  box-sizing: border-box;
  @media (min-width: ${theme.devices.tablet.max}) {
    width: 20rem;
  }
`;

export const FiltersHeaderContainer = styled(Flex.Column)`
  ${container}
  & > div:not(:last-of-type) {
    padding-bottom: 1rem;
  }
  padding: 0.75rem 2rem;
  ${tabletAndMobileOnlyMixin}
  button {
    padding: 0;
  }
`;

export const FiltersAndSearchButtonContainer = styled.div<{ showFiltersPanelForm: boolean }>`
  position: relative;
  padding: 1.5rem 2rem;
  @media (max-width: ${theme.devices.tablet.max}) {
    display: ${({ showFiltersPanelForm }) => (showFiltersPanelForm ? 'block' : 'none')};
  }
  ${container}
`;

export const FiltersContainer = styled.div`
  width: 100%;
`;

export const CustomSearchButton = styled(SearchButton)`
  top: 50%;
  right: -0.5rem;
  position: absolute;
  z-index: 9999;
  transform: translateY(-50%);
  ${tabletAndMobileOnlyMixin}
`;

export const Label = styled.label`
  display: block;
  font-family: ${theme.typography.fontFamily.regular};
  font-weight: ${theme.typography.fontWeight.bold};
`;

export const FilterLabel = styled(Label)`
  padding-left: 1.75rem;
  padding-bottom: 0.5rem;
`;

export const FilterFieldContainer = styled.div`
  padding: 0.5rem 0.5rem 0;
  margin-bottom: 1rem;
`;

export const HeaderTitle = styled(Label)`
  text-transform: uppercase;
`;

export const Text = styled.span`
  font-family: ${theme.typography.fontFamily.regular};
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.s};
`;
