import RCSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useEffect, useState } from 'react';

import { useSearchFormContext } from '~/components/Welcome/useSearchFormContext';
import { maxDistance, minDistance } from '~/constants/jobOfferSearch';

import { SliderContainer, sliderStyles } from './DistanceSlider.style';

type SliderProps = {
  onCurrentDistanceChange?: (value: number) => void;
};

export const DistanceSlider = ({ onCurrentDistanceChange }: SliderProps) => {
  const {
    form: {
      distance: { value, onChange },
    },
  } = useSearchFormContext();

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (onCurrentDistanceChange) {
      onCurrentDistanceChange(currentValue);
    }
  }, [currentValue]);

  return (
    <SliderContainer>
      <RCSlider
        step={1}
        min={minDistance}
        max={maxDistance}
        value={currentValue}
        trackStyle={sliderStyles.track}
        handleStyle={sliderStyles.handle}
        railStyle={sliderStyles.rail}
        onAfterChange={onChange}
        onChange={setCurrentValue as any}
      />
    </SliderContainer>
  );
};
